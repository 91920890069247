import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { db } from '@/lib/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '@/context/auth-context';

const colorThemeAtom = atomWithStorage<string>(
  'color-theme',
  'green'
);

export function useColorTheme() {
  const [colorTheme, setColorTheme] = useAtom(colorThemeAtom);
  const { user } = useAuth();

  useEffect(() => {
    const fetchColorTheme = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setColorTheme(userData.colorTheme || 'green');
        }
      }
    };

    fetchColorTheme();
  }, [user, setColorTheme]);

  const updateColorTheme = async (newColorTheme: string) => {
    setColorTheme(newColorTheme);
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { colorTheme: newColorTheme });
    }
  };

  return [colorTheme, updateColorTheme] as const;
}
